import { BwaMonth } from "./bwa";
import { InvoiceRevenueData } from "./invoicerevenues";
import axios from "axios";

export interface BwaMonthRevenue {
    readonly bwaMonth: BwaMonth;
    readonly billingAccountCount: number;
    readonly partitionCount: number;
    readonly dealerCount: number;
    readonly invoiceTotal: number;
    readonly unpaidInvoiceTotal: number;
    readonly unpaidInvoiceCount: number;
    readonly invoiceRevenueData: InvoiceRevenueData;
}

export interface BwaMonthDealerRevenue {
    readonly bwaMonth: BwaMonth;
    readonly billingAccountCount: number;
    readonly partitionId: string | null;
    readonly dealerId: string | null;
    readonly unpaidRevenueTotal: number;
    readonly unpaidInvoiceCount: number;
    readonly invoiceRevenueData: InvoiceRevenueData;
}

interface BwaRevenuesApi {
    getAll(): Promise<BwaMonthRevenue[]>;
    getDealerRevenuesByBwaMonthRange(from: string, to: string): Promise<BwaMonthDealerRevenue[]>;
}

export const bwaRevenuesApi: BwaRevenuesApi = {
    async getAll() {
        return (await axios.get("/api/bwa-revenues/monthly")).data;
    },

    async getDealerRevenuesByBwaMonthRange(from, to) {
        return (
            await axios.get("/api/bwa-revenues/dealers", {
                params: { from, to },
            })
        ).data;
    },
};
