
import DutyRosterPanel from "./DutyRosterPanel.vue";
import EditAgentDialog from "./EditAgentDialog.vue";
import { BdcTeam, bdcTeamsApi } from "@/api/bdcTeams";
import { dutyRostersApi } from "@/api/dutyRosters";
import { User, usersApi } from "@/api/users";
import MonthPicker from "@/app/components/MonthPicker.vue";
import { searchFilter } from "@/app/searchFilter";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getCurrentMonth, getWeek, startOf, toDateObject, UnitOfTime, Week } from "@/util/dateTimeUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";

interface Agent {
    readonly id: string;
    readonly name: string;
    readonly usedCapacity: number;
    readonly weeklyWorkingHours: number;
}

export default Vue.extend({
    data() {
        const timeZone = configStore.configuration.organisationTimeZone;
        const ts = now();

        return {
            bdcTeams: [] as readonly BdcTeam[],
            locale: configStore.configuration.defaultLocale,
            localFilter: {
                bdcTeamIds: [] as string[],
            },
            month: getCurrentMonth(ts, timeZone),
            openWeek: null as number | null,
            search: null as string | null,
            selectedAgentId: null as string | null,
            timeZone,
            user: null as User | null,
            users: [] as User[],
            validCapacity: true,
            weeklyScheduledMinutesByUserId: {} as { [userId: string]: number[] },
        };
    },

    computed: {
        agents(): Agent[] {
            return this.users
                .filter((u) => u.weeklyWorkingHours !== null)
                .map((u) => ({
                    id: u.id,
                    name: `${u.givenName} ${u.familyName} (${u.username})`,
                    usedCapacity:
                        (100 * (this.weeklyScheduledMinutesByUserId[u.id] || []).reduce((p, c) => p + c, 0)) /
                        (this.weeks.length * u.weeklyWorkingHours! * 60),
                    weeklyWorkingHours: u.weeklyWorkingHours!,
                }))
                .sort((a, b) => a.name.localeCompare(b.name, userSession.locale));
        },

        bdcTeamOptions(): SelectOption[] {
            return this.bdcTeams.map((bdcTeam) => ({
                text: `${bdcTeam.name} (${this.$tc(
                    "0 Agenten | 1 Agent | {count} Agenten",
                    bdcTeam.memberUserIds.length
                )})`,
                value: bdcTeam.id,
            }));
        },

        filteredAgents(): Agent[] {
            return this.agents
                .filter(
                    (agent) =>
                        !this.localFilter.bdcTeamIds.length || this.memberUserIdsOfSelectedBdcTeams.includes(agent.id)
                )
                .filter((a) => searchFilter(this.search, a.name));
        },

        memberUserIdsOfSelectedBdcTeams(): string[] {
            return this.bdcTeams
                .filter((bdcTeam) => this.localFilter.bdcTeamIds.includes(bdcTeam.id))
                .map((bdcTeam) => bdcTeam.memberUserIds)
                .reduce((prev, cur) => prev.concat(cur), []);
        },

        selectedAgent(): Agent | null {
            if (!this.selectedAgentId) {
                return null;
            }

            return this.agents.find((a) => a.id === this.selectedAgentId) ?? null;
        },

        weeks(): Week[] {
            const firstDayOfMonth = toDateObject(this.timeZone, this.month + "-01");
            const firstDayOfWeek = startOf(firstDayOfMonth, this.timeZone, this.locale, UnitOfTime.WEEK);

            return [...Array(8).keys()]
                .map((offset) => getWeek(firstDayOfWeek, offset, this.timeZone, this.locale))
                .filter((week) => week.begin.startsWith(this.month) || week.end.startsWith(this.month));
        },
    },

    methods: {
        async loadBdcTeams() {
            this.bdcTeams = await bdcTeamsApi.getAll();
        },

        async loadWeeklyScheduledMinutesByUserId() {
            this.validCapacity = false;
            try {
                this.weeklyScheduledMinutesByUserId = await dutyRostersApi.getScheduledTime(
                    this.weeks[0].begin,
                    this.weeks.length
                );
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            } finally {
                this.validCapacity = true;
            }
        },

        async loadWeeklyScheduledMinutesOfUser(userId: string) {
            Vue.set(
                this.weeklyScheduledMinutesByUserId,
                userId,
                await dutyRostersApi.getScheduledTimeByUser(userId, this.weeks[0].begin, this.weeks.length)
            );
        },

        async loadUsers() {
            this.user = null;

            this.users = await usersApi.list();
        },

        getUsedCapacity(weekIndex: number) {
            if (!this.selectedAgent || !this.validCapacity) {
                return NaN;
            }

            const t = this.weeklyScheduledMinutesByUserId[this.selectedAgent.id];

            if (!t) {
                return 0;
            }

            const c = (100 * t[weekIndex]) / (this.selectedAgent.weeklyWorkingHours * 60);

            return isNaN(c) ? 0 : c;
        },

        showEditAgentDialog(userId: string) {
            this.user = this.users.find((u) => u.id === userId) || null;
        },
    },

    watch: {
        filteredAgents(newValue: Agent[]) {
            if (newValue.find((a) => a.id === this.selectedAgentId)) {
                return;
            }

            this.openWeek = null;
            this.selectedAgentId = null;
        },

        month() {
            this.openWeek = null;
        },

        async weeks() {
            await this.loadWeeklyScheduledMinutesByUserId();
        },
    },

    async mounted() {
        await this.loadBdcTeams();
        await this.loadUsers();
        await this.loadWeeklyScheduledMinutesByUserId();
    },

    components: {
        DutyRosterPanel,
        MonthPicker,
        EditAgentDialog,
    },
});
