
import MonthlyBwaRevenueDataTableRow from "./MonthlyBwaRevenueDataTableRow.vue";
import { BwaMonthRevenue, bwaRevenuesApi } from "@/api/bwarevenues";
import CsvDownloadIcon from "@/app/components/CsvDownloadIcon.vue";
import { addInvoiceRevenueData, getOtherRevenue } from "@/app/pages/invoicerevenue/invoiceRevenueUtils";
import { getFileBasename } from "@/app/pages/reporting/downloadUtils";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import Vue from "vue";

type Total = Omit<BwaMonthRevenue, "bwaMonth">;

export default Vue.extend({
    data() {
        return {
            items: [] as BwaMonthRevenue[],
            loading: true,
            now: now(),
            searchId: 1,
        };
    },

    computed: {
        csvRevenueData(): string[][] {
            return [
                [
                    this.$t("Monat") as string,
                    this.$t("Gesamtumsatz") as string,
                    this.$t("Software") as string,
                    this.$t("BDC (wiederkehrend)") as string,
                    this.$t("BDC (variabel)") as string,
                    this.$t("Click-to-Call") as string,
                    this.$t("Call-Tracking") as string,
                    this.$t("SMS") as string,
                    this.$t("WhatsApp") as string,
                    this.$t("Einrichtung") as string,
                    this.$t("Sonstiges") as string,
                ],
                ...this.items.map((i) => [
                    i.bwaMonth.month,
                    this.formatPriceForCsv(i.invoiceRevenueData.totalRevenue),
                    this.formatPriceForCsv(i.invoiceRevenueData.softwareRevenue),
                    this.formatPriceForCsv(i.invoiceRevenueData.fixedBdcRevenue),
                    this.formatPriceForCsv(i.invoiceRevenueData.variableBdcRevenue),
                    this.formatPriceForCsv(i.invoiceRevenueData.clickToCallRevenue),
                    this.formatPriceForCsv(i.invoiceRevenueData.callTrackingRevenue),
                    this.formatPriceForCsv(i.invoiceRevenueData.outgoingSmsRevenue),
                    this.formatPriceForCsv(i.invoiceRevenueData.whatsAppNumberRevenue),
                    this.formatPriceForCsv(i.invoiceRevenueData.setupRevenue),
                    this.formatPriceForCsv(getOtherRevenue(i.invoiceRevenueData)),
                ]),
            ];
        },

        csvRevenueFilename(): string | null {
            if (!this.items.length) {
                return null;
            }

            const prefix = getFileBasename(
                this.$t("BWA") as string,
                this.items[this.items.length - 1].bwaMonth.month,
                this.items[0].bwaMonth.month,
                this.$t("Umsatz") as string
            );

            return `${prefix}.csv`;
        },

        locale(): string {
            return configStore.configuration.defaultLocale;
        },

        timezone(): string {
            return configStore.configuration.organisationTimeZone;
        },

        total(): Total | null {
            if (!this.items.length) {
                return null;
            }

            return this.items
                .map((item) => item as Total)
                .reduceRight((a, b) => ({
                    billingAccountCount: a.billingAccountCount + b.billingAccountCount,
                    partitionCount: a.partitionCount + b.partitionCount,
                    dealerCount: a.dealerCount + b.dealerCount,
                    invoiceTotal: a.invoiceTotal + b.invoiceTotal,
                    unpaidInvoiceTotal: a.unpaidInvoiceTotal + b.unpaidInvoiceTotal,
                    unpaidInvoiceCount: a.unpaidInvoiceCount + b.unpaidInvoiceCount,
                    invoiceRevenueData: addInvoiceRevenueData(a.invoiceRevenueData, b.invoiceRevenueData),
                }));
        },
    },

    methods: {
        formatPriceForCsv(value: number): string {
            return this.$n(value, {
                minimumFractionDigits: "2",
                maximumFractionDigits: "4",
                useGrouping: false as any, // interface requires string, but the implementation a number
            });
        },

        async loadItems() {
            this.items = [];
            this.loading = true;
            const searchId = ++this.searchId;

            try {
                const bwaMonthlyRevenues = await bwaRevenuesApi.getAll();

                if (searchId === this.searchId) {
                    this.items = bwaMonthlyRevenues;
                }
            } finally {
                if (searchId === this.searchId) {
                    this.loading = false;
                }
            }
        },

        async refresh() {
            await this.loadItems();
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        CsvDownloadIcon,
        MonthlyBwaRevenueDataTableRow,
    },
});
