
import CitnowSalesforceUploadUsageRecordsSummariesUploadDialog from "./CitnowSalesforceUploadUsageRecordsSummariesUploadDialog.vue";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            usageRecordsSummariesUploadDialogVisible: false,
            working: false,
        };
    },

    computed: {
        locale(): string {
            return configStore.configuration.defaultLocale;
        },

        timeZone(): string {
            return configStore.configuration.organisationTimeZone;
        },
    },

    components: {
        CitnowSalesforceUploadUsageRecordsSummariesUploadDialog,
    },
});
