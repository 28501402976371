import axios from "axios";

interface CitnowSalesforceApi {
    uploadUsageRecordsSummaries(from: Date, to: Date): Promise<void>;
}

export const citnowSalesforceApi: CitnowSalesforceApi = {
    async uploadUsageRecordsSummaries(from, to) {
        await axios.post("/api/citnow-salesforce/_upload-usage-records-summaries", undefined, {
            params: {
                from,
                to,
            },
        });
    },
};
